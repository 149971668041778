import React, { ReactElement } from 'react';

import { RightOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip } from 'antd';
import cx from 'classnames';
import { debounce } from 'lodash';
import { action, computed, observable } from 'mobx';

import './ScoreBarRow.scss';

import ScoreBar from 'app/components/features/ScoreBar';
import { ENTER_KEY_CODE } from 'app/constants/keyMapping';

export type HoverOrFocusEvent = {
  target: EventTarget;
};

export interface ScoreBarRowProps {
  onClick?: React.MouseEventHandler;
  label: string;
  /** Tooltip defaults to "Click here to view insights". Pass your own string, or pass `null` to suppress tooltip */
  tooltip?: string;

  circleValue?: number;
  circleColor?: string;
  circleClassName?: string;
  circleLabel?: string;
  circleMeta?: { tooltip?: string };

  rowColor?: string;
  diamondValue?: number;

  className?: string;
  rowWidthClass?: string;
  showArrow?: boolean;
  pins?: { value: number; label: string }[];
  otherBenchmarks?: { value: number; marker: ReactElement; tooltip?: string }[];
  /**
   * If only some rows should show this, you probably want `visibility: hidden` in the rows that don't — for alignment.
   */
  customIcon?: ReactElement;
  showRowButton?: boolean;
  selected?: boolean;
  /** Show checkbox; empty/filled based on the `selected` prop value */
  showCheckbox?: boolean;
  // Themed is a styling props
  themed?: boolean;
  themedActive?: boolean;
  onHoveredOrFocused?: (event: HoverOrFocusEvent) => void;
}

/**
 * TooltipShownStore is useful when using `onHoveredOrFocused`. We're *expecting* the user to hover a bunch
 * of rows... but after a few rows the tooltip gets annoying.
 * - We want the tooltip count to be "static" across all instances of ScoreBarRow, so we can stop showing it after
 *   you've hovered different rows. (And the parent of is sometimes multiple components, e.g. `FocusAreaSection`s)
 * - Although Mobx 4 doesn't have built-in static observables, this approach here of a litte store was suggested as a
 *   workound in https://github.com/mobxjs/mobx/issues/351, and it achieves the goal, yay.
 */
class TooltipShownStore {
  @observable private seenItCount = 0;
  @action increment = () => {
    this.seenItCount++;
  };
  @computed get hasSeenEnoughTooltip(): boolean {
    return this.seenItCount >= 3;
  }
}

const tooltipCount = new TooltipShownStore();

export const ScoreBarRow: React.FC<ScoreBarRowProps> = ({
  label,
  tooltip,
  circleValue,
  circleColor,
  circleClassName,
  circleLabel,
  circleMeta,
  rowColor,
  diamondValue,
  onClick,
  onHoveredOrFocused,
  className,
  rowWidthClass,
  showArrow,
  otherBenchmarks,
  pins,
  selected,
  showCheckbox,
  themed,
  themedActive,
  showRowButton,
  customIcon,
}) => {
  // For accessibility.
  const onKeyDown = (event) => {
    if (onClick && event.keyCode === ENTER_KEY_CODE) {
      onClick(event);
    }
  };

  // Debouncing mouseEnter: This is to prevent deselecting the row when you move your mouse "away" from the row.
  //   - It is normal in mouse movement that when moving away the row, you might move "across" adjacent rows.
  //   - UX principle: User "intent" to hover == they pause 0.1-0.3s over target (https://ux.stackexchange.com/a/109290)
  //   - Ant Design Tooltip mouseEnterDelay is 100ms, but in my testing that felt "laggy", 50ms feels better. I like it.
  const debouncedMouseEnter = debounce((event: HoverOrFocusEvent) => {
    if (document.activeElement.classList.contains('score-bar-row-container')) {
      // Edge case: If user had previously focused another ScoreBarRow (keyboard), we want to remove that focus.
      (document.activeElement as HTMLElement)?.blur?.();
    }
    onHoveredOrFocused?.(event);
  }, 50);
  const handleOnMouseLeave = () => debouncedMouseEnter.cancel();

  const clickable = !!onClick;
  const hoverFocusable = !!onHoveredOrFocused;
  const hoverable = clickable || hoverFocusable;

  const optionalCheckbox = showCheckbox ? (
    <span className="big-checkbox">
      <Checkbox checked={selected} />
    </span>
  ) : null;

  const row = (
    <div
      className={cx('row align-items-center score-bar-row', rowColor, {
        themed,
        'selected-active': selected,
        'themed-active': themedActive,
        nonHoverable: !hoverable,
      })}
    >
      <span className="col-md-6 d-flex align-items-center">
        {optionalCheckbox}
        {label}
      </span>
      <div className={'col-md-6 d-flex align-items-center no-gutters'}>
        <ScoreBar
          className={cx(rowWidthClass || 'w-100')}
          circleColor={circleColor}
          circleValue={circleValue}
          circleClassName={circleClassName}
          circleLabel={circleLabel}
          circleTooltip={circleMeta?.tooltip}
          diamondValue={diamondValue}
          pins={pins}
          showArrow={showArrow}
          otherBenchmarks={otherBenchmarks}
        />

        {(customIcon || showRowButton) && (
          <div className="row-icons">
            {customIcon}
            {showRowButton && <RightOutlined />}
          </div>
        )}
      </div>
    </div>
  );

  const rowBar = (
    <div
      className={cx('score-bar-row-container', className)}
      onClick={clickable ? onClick : undefined}
      onKeyDown={onKeyDown}
      onMouseEnter={debouncedMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onFocus={onHoveredOrFocused}
      tabIndex={clickable ? 0 : undefined}
      role={clickable ? 'option' : 'presentation'}
      aria-selected={selected || undefined}
    >
      {row}
    </div>
  );

  const handleTooltipChange = (nowVisible: boolean) => {
    if (nowVisible) {
      tooltipCount.increment();
    }
  };

  const showTooltip = clickable && tooltip !== null && !tooltipCount.hasSeenEnoughTooltip;
  const tooltipTitle = showTooltip ? tooltip ?? 'Click to view insights' : undefined;

  return (
    <Tooltip
      title={tooltipTitle}
      mouseEnterDelay={0.4}
      onOpenChange={handleTooltipChange}
      trigger={['hover', 'focus']}
    >
      {rowBar}
    </Tooltip>
  );
};

export default ScoreBarRow;
