import React, { ReactNode } from 'react';

import {
  BookOutlined,
  DollarOutlined,
  DownOutlined,
  LogoutOutlined,
  ReadOutlined,
  StopOutlined,
  TagOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';
import { inject } from 'mobx-react';

import { AppLayoutUiStore } from 'app/components/features/AppLayout/stores';
import Link from 'app/components/ui/Link';
import { STORE_APP_LAYOUT_UI } from 'app/constants';
import { MenuItemModel } from 'app/models';

const icons = {
  book_outlined: BookOutlined,
  down_outlined: DownOutlined,
  logout_outlined: LogoutOutlined,
  read_outlined: ReadOutlined,
  stop_outlined: StopOutlined,
  tag_outlined: TagOutlined,
  dollar_outlined: DollarOutlined,
  team_outlined: TeamOutlined,
};

const makeIcon = (menuItem: MenuItemModel): ReactNode => {
  if (!menuItem.icon) {
    return null;
  }

  const icon = icons[menuItem.icon];
  return React.createElement(icon);
};

const makeLabelTag = (menuItem: MenuItemModel): ReactNode => {
  if (!menuItem.label) {
    return null;
  }

  return <Tag>{menuItem.label}</Tag>;
};

interface MenuLinkProps {
  menuItem: MenuItemModel;
  className?: string;
  appLayoutUiStore?: AppLayoutUiStore;
}

// This component wraps the Link component and adds logic for using the routed link or plain href
const MenuLink: React.FC<MenuLinkProps> = ({ menuItem, appLayoutUiStore, children, ...rest }) => {
  const useLink = !!appLayoutUiStore?.useRouter && menuItem.routed;
  let url = menuItem.url;

  if (menuItem.appends_referrer) {
    url += `?referrer=${window.location.pathname}`;
  }

  const icon = makeIcon(menuItem);
  const label = makeLabelTag(menuItem);

  return (
    <Link url={url} {...rest} useLink={useLink}>
      {icon} {children ?? menuItem.name} {label}
    </Link>
  );
};

export default inject(STORE_APP_LAYOUT_UI)(MenuLink);
