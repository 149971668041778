import { History } from 'history';
import { isEmpty, sortBy } from 'lodash';
import { action, computed, observable, ObservableMap } from 'mobx';

import { ScaleBarModalItem } from 'app/components/features/ScaleBar/ScaleBarStack/ScaleBarStack';
import {
  CONTENT_KEYS,
  ENTITY_STATES,
  PerspectiveDimension,
  PerspectiveSpreadType,
  ToolPath,
  UNIVERSAL_CONTENT_KEY,
} from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import {
  LensScoreWithMembers,
  MemberModel,
  PerspectiveDimensionCompact,
  PerspectiveMemberModel,
  ToolSurveyAnswerModel,
} from 'app/models';
import {
  EntityStateStore,
  FeatureStore,
  IntelligenceActionStore,
  MemberStore,
  NavigationStore,
  OrganizationStore,
  PairedNoteStore,
  PerspectiveManagerInsightsStore,
  PerspectiveStore,
  TeamStore,
} from 'app/stores';
import MemberRatingStore from 'app/stores/MemberRatingStore';

export interface PairInfo {
  memberId: number;
  teamId?: number;
  currentMemberId?: number;
}

interface PerspectiveReportUIStoreProps {
  testDriveInfo?: PairInfo;
  token?: string;
  history: History<any>;

  perspectiveStore: PerspectiveStore;
  featureStore?: FeatureStore;
  memberStore?: MemberStore;
  teamStore?: TeamStore;
  organizationStore?: OrganizationStore;
  navigationStore?: NavigationStore;
  pairedNoteStore?: PairedNoteStore;
  memberRatingStore?: MemberRatingStore;
  entityStateStore?: EntityStateStore;
  perspectiveManagerInsightsStore?: PerspectiveManagerInsightsStore;
  intelligenceActionStore: IntelligenceActionStore;
}

export interface ActiveLens {
  key: string;
  index: number;
}

export default class PerspectiveReportUIStore {
  /**
   * Configuration
   */
  history: History<any>;
  testDriveInfo: PairInfo;
  token: string;

  @observable exploreActiveLens: ObservableMap<string, number> = new ObservableMap();
  @action setExploreActiveLens = (activeLens: ActiveLens): void => {
    this.exploreActiveLens.set(activeLens.key, activeLens.index);
  };

  @observable isShowingPerspectiveVisibilityNotification = false;
  @action setIsShowingPerspectiveVisibilityNotification = (
    isShowingPerspectiveVisibilityNotification: boolean
  ) =>
    (this.isShowingPerspectiveVisibilityNotification = isShowingPerspectiveVisibilityNotification);

  @observable redirectPage;
  @action setRedirectPage = (url) => (this.redirectPage = url);

  @observable redirectLabel: string;
  @action setRedirectLabel = (label: string): string => (this.redirectLabel = label);

  @observable pairInfo: PairInfo;
  @action setPairInfo = (pairInfo: PairInfo): void => {
    this.pairInfo = pairInfo;
  };

  @observable selectedScaleBarModalItem: ScaleBarModalItem;
  @action setSelectedStackModalItem = (scaleBarModalItem: ScaleBarModalItem): void => {
    this.selectedScaleBarModalItem = scaleBarModalItem;
  };

  @observable selectedScaleBarModalTitle: string;
  @action setSelectedScaleBarModalTitle = (title: string): void => {
    this.selectedScaleBarModalTitle = title;
  };

  @observable breakdownForDimension: string;
  @action setBreakdownForDimension = (breakdownForDimension: string): void => {
    this.breakdownForDimension = breakdownForDimension;
  };

  @observable teamDiscussionSelectedMemberIds: number[] = [];
  @action setTeamDiscussionSelectedMemberIds = (memberIds: number[]): void => {
    this.teamDiscussionSelectedMemberIds = memberIds;
  };

  /**
   * Stores
   */
  perspectiveStore: PerspectiveStore;
  featureStore?: FeatureStore;
  memberStore?: MemberStore;
  teamStore?: TeamStore;
  organizationStore?: OrganizationStore;
  navigationStore?: NavigationStore;
  pairedNoteStore?: PairedNoteStore;
  memberRatingStore?: MemberRatingStore;
  perspectiveManagerInsightsStore?: PerspectiveManagerInsightsStore;
  entityStateStore?: EntityStateStore;
  intelligenceActionStore: IntelligenceActionStore;

  /**
   * Constructor
   */
  constructor(props: PerspectiveReportUIStoreProps) {
    this.testDriveInfo = props.testDriveInfo;
    this.token = props.token;

    this.history = props.history;
    this.perspectiveStore = props.perspectiveStore;
    this.featureStore = props.featureStore;
    this.memberStore = props.memberStore;
    this.teamStore = props.teamStore;
    this.organizationStore = props.organizationStore;
    this.navigationStore = props.navigationStore;
    this.pairedNoteStore = props.pairedNoteStore;
    this.memberRatingStore = props.memberRatingStore;
    this.entityStateStore = props.entityStateStore;
    this.perspectiveManagerInsightsStore = props.perspectiveManagerInsightsStore;
    this.intelligenceActionStore = props.intelligenceActionStore;

    this.perspectiveStore.setSharedLinkToken(this.token);
    this.memberStore?.setSharedLinkToken(this.token);
    this.teamStore?.setSharedLinkToken(this.token);
    this.memberRatingStore?.setSharedLinkToken(this.token);
    this.pairedNoteStore?.setSharedLinkToken(this.token);
    this.perspectiveManagerInsightsStore?.setSharedLinkToken(this.token);
    this.intelligenceActionStore.setSharedLinkToken(this.token);

    this.perspectiveStore?.setIsTestDrive(this.isTestDrive);
    this.memberRatingStore?.setIsTestDrive(this.isTestDrive);
    this.memberStore?.setIsTestDrive(this.isTestDrive);
    this.teamStore?.setIsTestDrive(this.isTestDrive);
    this.perspectiveManagerInsightsStore?.setIsTestDrive(this.isTestDrive);
  }

  setSeenPerspectiveVisibilityNotification(): void {
    const entityState = this.perspectiveVisibilityEntityState;
    this.setIsShowingPerspectiveVisibilityNotification(!entityState?.flag);
  }

  get isEntityStatesLoaded(): boolean {
    return this.isMemberEntityStatesLoaded;
  }

  @computed
  get isTestDrive(): boolean {
    return !!this.testDriveInfo;
  }

  /**
   * Returns the 'back_to' url if provided, or fallbcak url. In both cases, if the user is on the report
   * via a shared link token, actaully return login page with a redirect to the final destination.
   */
  getRedirectURL = (fallbackDefaultDashboardUrl: string): string => {
    // If the user came to this report by navigating from the dashboard Perspective Home page, prefer that
    // redirectPage. But fall back to a default, for when they landed on this report directly.
    const url = this.redirectPage ?? fallbackDefaultDashboardUrl;

    return this.perspectiveStore.sharedLinkToken
      ? `${ServerRouteHelper.auth.login()}?redirect_to=${url}`
      : url;
  };

  /**
   * "what page should we take users to by default, if they landed directly on this report?
   * i.e. when they didn't come here by navigating from the dashboard Perspective Home page?"
   */
  get perspectiveHomeURL(): string {
    const team = this.teamStore.team?.item;
    if (team) {
      return ServerRouteHelper.dashboard.perspectiveHome(team.organization_id, team.id);
    }

    return ServerRouteHelper.dashboard.perspectiveHomeDefault();
  }

  /**
   * My Perspective report has a different requirement for default 'Return to dashboard" page: users need to
   * go to the dashboard home page, because there's flows (e.g. open the edit team modal when an entity state)
   * that we don't want to put effort into moving to Perspective Home page at this time (Nov 2024).
   */
  get dashboardHomeURL(): string {
    if (this.teamStore.team.item) {
      return ServerRouteHelper.dashboard.teamPage(this.teamStore.team.item.id, ToolPath.Home);
    }

    return ServerRouteHelper.dashboard.home();
  }

  @computed
  get perspectiveVisibilityEntityState() {
    return this.entityStateStore.memberEntityStates.items.find(
      (item) => item.type === ENTITY_STATES.SEEN_PERSPECTIVE_VISIBILITY_TOGGLE_NOTIFICATION
    );
  }

  @computed
  get isScaleBarModalOpen(): boolean {
    return !!this.selectedScaleBarModalItem;
  }

  get teamNonParticipant(): PerspectiveMemberModel[] {
    return this.perspectiveStore.teamNonParticipants.items;
  }

  get isMemberEntityStatesLoaded(): boolean {
    return this.entityStateStore.memberEntityStates.loaded;
  }

  get inviterMember(): MemberModel {
    return this.perspectiveStore.inviter.item;
  }

  // Get current member
  get currentMember(): MemberModel {
    return this.memberStore.currentMember.item;
  }

  @computed
  get canManageTeam(): boolean {
    return !!this.perspectiveStore.teamPerspective?.canManage;
  }

  @computed
  get isManagerCoachDisabled(): boolean {
    return !!this.perspectiveStore.teamPerspective?.isManagerCoachDisabled;
  }

  @computed
  get dimensionsMap(): { [dimension: string]: PerspectiveDimensionCompact } {
    const { teamPerspective, isLoadingTeamPerspective } = this.perspectiveStore;

    if (isLoadingTeamPerspective || !teamPerspective) {
      return {};
    }

    return teamPerspective.getTeamStackItems();
  }

  @computed
  get dimensionsSortedBySpread(): PerspectiveDimensionCompact[] {
    const dimensions = Object.values(this.dimensionsMap);

    return sortBy(dimensions, ({ spreadType }) => {
      switch (spreadType) {
        case PerspectiveSpreadType.Blindspot:
          return 0;
        case PerspectiveSpreadType.Tilted:
          return 1;
        case PerspectiveSpreadType.Balanced:
          return 2;
      }
    });
  }

  @computed
  get discussionDimensions(): PerspectiveDimensionCompact[] {
    const dimensions = Object.values(this.dimensionsMap);

    const blindspots = this.sortByDimension(
      dimensions.filter(({ spreadType }) =>
        [PerspectiveSpreadType.Blindspot, PerspectiveSpreadType.Tilted].includes(spreadType)
      )
    );

    const balanced = this.sortByDimension(
      dimensions.filter(({ spreadType }) => spreadType === PerspectiveSpreadType.Balanced)
    );

    return [...blindspots, ...balanced];
  }

  sortByDimension = (dimensions: PerspectiveDimensionCompact[]): PerspectiveDimensionCompact[] => {
    return sortBy(dimensions, ({ name }) => {
      switch (name) {
        case PerspectiveDimension.Structure:
          return 0;
        case PerspectiveDimension.Energy:
          return 1;
        case PerspectiveDimension.Decisions:
          return 2;
        case PerspectiveDimension.Processing:
          return 3;
      }
    });
  };

  @computed
  get breakdownLensesScores(): { [lens: string]: LensScoreWithMembers } {
    return this.perspectiveStore.teamPerspective?.lensesScores?.[this.breakdownForDimension] ?? {};
  }

  @computed
  get hasLensesScores(): boolean {
    return !isEmpty(this.perspectiveStore.teamPerspective?.lensesScores);
  }

  getLensResonateType = (type: string, leftLabel: string, rightLabel: string): string => {
    // Strip short version from labels, ie. Extraversion (E)
    leftLabel = leftLabel.replace(/\(.*\)/, '').trim();
    rightLabel = rightLabel.replace(/\(.*\)/, '').trim();
    return `${type}-lens-${leftLabel}:${rightLabel}`;
  };

  getResonateTag = (type: string): string => {
    return `perspective:personal:${type}`;
  };

  getResonateAnswer = (tag: string): ToolSurveyAnswerModel => {
    const answers = this.perspectiveStore.surveyAnswers.items ?? [];
    return answers.find((answer) => answer.tag === tag);
  };

  getLensResonateAnswer = (resonateType: string): string => {
    const tag = this.getResonateTag(resonateType);
    const answer = this.getResonateAnswer(tag);
    return answer?.answer;
  };

  saveSurveyAnswer = (type: string, answer: string): void => {
    const tag = this.getResonateTag(type);
    this.perspectiveStore.saveSurveyAnswer(tag, answer);
  };

  handleRetake = (): void => {
    this.perspectiveStore.retake(this.currentMember.id);
    this.history.push(ServerRouteHelper.perspective.questions());
  };

  handleLensBreakdown = (dimension: string): void => {
    this.setBreakdownForDimension(dimension);

    const params = new URLSearchParams();
    params.append(UNIVERSAL_CONTENT_KEY, CONTENT_KEYS.TEAM_PERSPECTIVE_LENS_BREAKDOWN);
    this.history.push({ search: params.toString() });
  };
}
