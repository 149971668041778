import React, { MouseEvent } from 'react';

import { inject, observer } from 'mobx-react';

import AlignFocusAreaSection from 'app/components/features/AlignFocusAreasSection';
import Card from 'app/components/ui/Card';
import { GREEN, RED, STORE_ENTITY_STATE, YELLOW } from 'app/constants';
import { EntityStateStore } from 'app/stores';

interface AlignFocusAreaSectionDemoProps {
  entityStateStore?: EntityStateStore;
}

const handleStatementClick = (event: MouseEvent, statementID: number) =>
  alert(`Selected statement ${statementID}`);

export class AlignFocusAreaSectionDemo extends React.Component<AlignFocusAreaSectionDemoProps> {
  render() {
    const propsDiagnose = {
      header: 'Areas to improve',
      color: RED,
      tooltip: 'Test Diagnose Tooltip',
      statements: [
        {
          id: 1,
          statement: "We admit if we are wrong or don't know",
          score: 20.0,
          benchmark: 50.0,
        },
      ],

      onStatementClick: handleStatementClick,
    };

    const propsDiscuss = {
      header: 'Discuss',
      color: YELLOW,
      tooltip: 'Test Discuss Tooltip',
      statements: [
        {
          id: 2,
          statement: "We don't let our gos get in the way",
          score: 50.0,
          benchmark: 85.0,
        },
        {
          id: 3,
          statement: 'We let good ideas come from anywhere',
          score: 55.0,
          benchmark: 60.0,
        },
        {
          id: 4,
          statement: 'We genuinely care about each other',
          score: 60.0,
          benchmark: 20.0,
        },
        {
          id: 5,
          statement: 'We actively surface & address problems',
          score: 55.0,
          benchmark: 90.0,
        },
      ],

      onStatementClick: handleStatementClick,
    };

    const propsCelebrate = {
      header: 'Celebrate',
      color: GREEN,
      tooltip: 'Test Celebrate Tooltip',
      statements: [
        {
          id: 6,
          statement: 'We deliver on our commitments',
          score: 80.0,
          benchmark: 50.0,
        },
        {
          id: 7,
          statement: 'We feel safe with one another',
          score: 90.0,
          benchmark: 95.0,
        },
      ],

      onStatementClick: handleStatementClick,
    };

    return (
      <div className="flex-fill m-4">
        <Card>
          <AlignFocusAreaSection {...propsDiagnose} />
          <AlignFocusAreaSection {...propsDiscuss} />
          <AlignFocusAreaSection {...propsCelebrate} />
        </Card>
      </div>
    );
  }
}

export default inject(STORE_ENTITY_STATE)(observer(AlignFocusAreaSectionDemo));
