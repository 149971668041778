import { History } from 'history';
import { action, computed, observable, when } from 'mobx';

import { ENTITY_STATES } from 'app/constants';
import { ServerRouteHelper } from 'app/helpers';
import {
  AlignModel,
  ExerciseReportModel,
  ExerciseStatementModel,
  MemberModel,
  OrganizationModel,
} from 'app/models';
import { EntityStateAssociationType } from 'app/models/EntityStateModel';
import ExerciseCommitmentModel, {
  ExerciseCommitmentCategory,
} from 'app/models/ExerciseCommitmentModel';
import {
  EntityStateStore,
  ExerciseActivityActions,
  ExerciseReportStore,
  ExerciseReportStoreKeys,
  FeatureStore,
  MemberStore,
  My360Store,
  OrganizationContentStore,
} from 'app/stores';

import { CommitToImproveModalStep } from './components/CommitToImproveModal/CommitToImproveModal';

export interface Manager360Filter {
  id: string;
  name: string;
  scores: Record<number, number>;
  type: string;
}

interface My360ReportUIStoreProps {
  isTestDrive?: boolean;
  token: string;
  history: History;

  printMode: string;
  isSigningUpForCoaching: boolean;

  exerciseReportStore: ExerciseReportStore;
  entityStateStore: EntityStateStore;
  organizationContentStore: OrganizationContentStore;
  my360Store: My360Store;
  memberStore: MemberStore;
  featureStore: FeatureStore;
}

// Report tabs
export enum ReportTab {
  DIGEST = 'analysis',
  REFLECTION = 'reflection',
  COMMITMENTS = 'commitments',
}

export default class My360ReportUIStore {
  /**
   * Configuration
   */
  isTestDrive?: boolean;
  token: string;
  history: History;

  /**
   * Observables
   */

  @observable activePage: string;
  @observable printMode: string;
  @observable isSigningUpForCoaching: boolean;
  @observable showingFeedbackAndGuidance = false;

  @observable showCommitToImproveModal: boolean;
  @observable commitToImproveModalExerciseCommitment: ExerciseCommitmentModel;
  @observable commitToImproveModalStep: CommitToImproveModalStep;
  @observable commitToImproveIsSubmitting: boolean;

  @observable showThankRespondentsModal: boolean;
  @observable thankRespondentsContent: string;
  @observable thankRespondentsIsSubmitting: boolean;
  @observable activeTab;
  @observable isSampleReflectionsDrawerOpen: boolean;
  @observable selectedGuidanceStatementId;
  @observable activeClassification;

  @observable hidePersonalResults = false;
  @action setHidePersonalResults = (hidePersonalResults: boolean): void => {
    this.hidePersonalResults = hidePersonalResults;
  };

  @observable showLandingModal: boolean;
  @action toggleShowLandingModal = () => (this.showLandingModal = !this.showLandingModal);
  @action setSelectedGuidanceStatementId = (statementId) =>
    (this.selectedGuidanceStatementId = statementId);
  @action setActiveClassification = (classification: string): void => {
    this.activeClassification = classification;
  };

  /**
   * Stores
   */

  exerciseReportStore: ExerciseReportStore;
  entityStateStore: EntityStateStore;
  my360Store: My360Store;
  memberStore: MemberStore;
  organizationContentStore: OrganizationContentStore;
  featureStore: FeatureStore;

  constructor(props: My360ReportUIStoreProps) {
    this.isTestDrive = !!props.isTestDrive;
    this.token = props.token;
    this.history = props.history;

    this.printMode = props.printMode;
    this.isSigningUpForCoaching = props.isSigningUpForCoaching;

    this.exerciseReportStore = props.exerciseReportStore;
    this.entityStateStore = props.entityStateStore;
    this.my360Store = props.my360Store;
    this.memberStore = props.memberStore;
    this.organizationContentStore = props.organizationContentStore;
    this.featureStore = props.featureStore;

    this.my360Store.setSharedLinkToken(this.token);
    this.my360Store.setIsTestDrive(this.isTestDrive);
  }

  /**
   * Actions
   */

  @action
  setPrintMode = (printMode) => (this.printMode = printMode);

  @action setActiveTab = (activeTab: string) => (this.activeTab = activeTab);

  @action toggleSampleReflectionsDrawer = () =>
    (this.isSampleReflectionsDrawerOpen = !this.isSampleReflectionsDrawerOpen);

  @action
  setActivePage = (page) => (this.activePage = page);

  @action
  toggleSigningUpForCoaching = () => (this.isSigningUpForCoaching = !this.isSigningUpForCoaching);

  // Commit to Improve

  @action
  setShowCommitToImproveModal = (v: boolean) => (this.showCommitToImproveModal = v);

  @action
  setCommitToImproveModalExerciseCommitment = (v: ExerciseCommitmentModel) =>
    (this.commitToImproveModalExerciseCommitment = v);

  @action
  setCommitToImproveModalStep = (value: CommitToImproveModalStep) =>
    (this.commitToImproveModalStep = value);

  @action
  setCommitToImproveIsSubmitting = (v: boolean) => (this.commitToImproveIsSubmitting = v);

  // Thank Respondents

  @action
  setShowThankRespondentsModal = (v: boolean) => (this.showThankRespondentsModal = v);

  @action
  setThankRespondentsContent = (v: string) => (this.thankRespondentsContent = v);

  @action
  setThankRespondentsIsSubmitting = (v: boolean) => (this.thankRespondentsIsSubmitting = v);

  @action
  setShowingFeedbackAndGuidance = (value: boolean) => (this.showingFeedbackAndGuidance = value);

  @observable manager360Filters: Manager360Filter[] = [];
  @action setManager360Filters = (filters) => {
    this.manager360Filters = filters;
  };

  /**
   * Models
   */

  @computed
  get report(): ExerciseReportModel {
    return this.exerciseReportStore.report;
  }

  @computed
  get activeStatement(): ExerciseStatementModel {
    return this.report.statementsById[this.selectedGuidanceStatementId];
  }

  @computed
  get exercise(): AlignModel {
    return this.report?.exercise;
  }

  @computed
  get currentMember(): MemberModel {
    return this.memberStore.currentMember.item;
  }

  @computed
  get organization(): Partial<OrganizationModel> {
    return this.report?.organization;
  }

  @computed
  get isLoading(): boolean {
    return (
      this.exerciseReportStore.isPending(ExerciseReportStoreKeys.ExerciseReport) ||
      this.featureStore.orgFeatures.loading
    );
  }

  @computed
  get affixOffset(): number {
    return 0;
  }

  /**
   * Convert the commitment to improvement category into an action.
   */
  @computed
  get commitToImproveModalExerciseAction(): ExerciseActivityActions {
    const category = this.commitToImproveModalExerciseCommitment.category;

    const mapping = {
      [ExerciseCommitmentCategory.celebrate]: ExerciseActivityActions.My360AnsweredMostProud,
      [ExerciseCommitmentCategory.summary]:
        ExerciseActivityActions.My360AnsweredUnexpectedStrengthWeakness,
      [ExerciseCommitmentCategory.improve]: ExerciseActivityActions.My360AnsweredFocusOnImproving,
    };

    return mapping?.[category];
  }

  @computed
  get isNextStepsPage(): boolean {
    return this.activePage === 'next_steps';
  }

  @computed
  get isRespondentsPage(): boolean {
    return this.activePage === 'respondents';
  }

  @computed
  get showHeaderActions(): boolean {
    return this.activePage === 'report';
  }

  @computed
  get loadingHasThankedRespondents(): boolean {
    return this.entityStateStore.exerciseEntityStates.loading;
  }

  /**
   * Loading method
   */

  public load(): void {
    if (this.token) {
      this.exerciseReportStore.getReportWithToken(this.token, true);
    }

    this.memberStore.checkOnlineStatus();

    // my360 doesn't support benchmarks
    when(
      () => !!this.report,
      () => {
        this.report.resetActiveBenchmark();
        this.loadMemberEntityStates();
        this.featureStore.loadOrgFeatures(this.organization.id);
      }
    );

    // When no report found, redirect home
    when(
      () => !this.isLoading && !this.report,
      () => (window.location.href = ServerRouteHelper.dashboard.home())
    );

    when(
      () => !!this.exercise && this.manager360Filters.length === 0,
      async () => {
        const filters = await this.my360Store.getManager360ReportFilters(this.token);
        this.setManager360Filters(filters);
      }
    );
  }

  /**
   * Given a report suffix, return equivalent view action.
   */
  mapSuffixToViewAction = (suffix: string): ExerciseActivityActions => {
    const mapping = {
      report: ExerciseActivityActions.My360ViewHighlightsReport,
      analysis: ExerciseActivityActions.My360ViewFullAnalysisReport,
      reflection: ExerciseActivityActions.My360ViewReflectionReport,
      commitments: ExerciseActivityActions.My360ViewCommitmentsReport,
    };

    return mapping?.[suffix];
  };

  private loadMemberEntityStates() {
    this.entityStateStore.loadMemberOwnedEntityStates(
      EntityStateAssociationType.Exercise,
      this.report.exercise.id,
      {
        types: [ENTITY_STATES.MY360_EXPECTED_RESULT, ENTITY_STATES.MY360_HOW_YOU_FEEL],
      },
      this.token
    );
  }
}
