import React, { MouseEvent, ReactElement } from 'react';

import { ThunderboltFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import cx from 'classnames';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import FlipMove from 'react-flip-move';

import './AlignFocusAreaSection.scss';

import { HoverOrFocusEvent, ScoreBarRow } from 'app/components/features/ScoreBarRow/ScoreBarRow';
import { STATEMENT_SECTION_COLORS, STORE_BREAKPOINT } from 'app/constants';
import { ExerciseReportModel, ScatterPlotPoint, SortOption } from 'app/models';

const DURATION_IN_MS = 500;
const STAGGER_DURATION_BY_IN_MS = 30;
/*
If you are animating 3 elements with a duration of 200 and a staggerDurationBy of 30:
The first element will take 200ms to transition.
The second element will take 230ms to transition.
The third element will take 260ms to transition.
*/
interface AlignFocusAreaSectionProps {
  header: string;
  color: string;
  tooltip?: string;
  noRowClick?: boolean;
  showArrow?: boolean;
  statements: {
    id: number;
    statement: string;
    score: number;
    scoreLabel?: string;
    scoreClasses?: string;
    scoreMeta?: { tooltip?: string };
    benchmark?: number;
    percentiles?: Array<{ value: number; label: string }>;
    showArrow?: boolean;
    selected?: boolean;
    themedActive?: boolean;
    hasStatementInsight?: boolean;
    color?: string;
    sectionColor?: string;
    otherBenchmarks?: any[];
    benchmarkColor?: string;
    allPoints?: ScatterPlotPoint[];
  }[];
  report?: ExerciseReportModel;
  hideStatementBenchmark?: boolean;
  hideSectionHeaders?: boolean;
  /**
   * Set this true if you want the report to show the "has insight" icon when `statement.hasStatementInsight` is true.
   * (But some reports, such as 360, don't have insights (as of Dec 2024), so we don't waste space on invisible icon.)
   */
  enableInsightIcon?: boolean;
  showRowButton?: boolean;
  isCompassionateConversation?: boolean;
  onSelectStatement?: (event: HoverOrFocusEvent, statementID: number) => void;
  onGetInsightClick?: (event: MouseEvent, statementID: number) => void;
}

export class AlignFocusAreaSection extends React.Component<AlignFocusAreaSectionProps> {
  @computed
  get statements(): ReactElement {
    const {
      statements,
      onSelectStatement,
      onGetInsightClick,
      showArrow,
      hideStatementBenchmark,
      enableInsightIcon,
      showRowButton,
      isCompassionateConversation,
      report,
    } = this.props;

    let sectionColor = STATEMENT_SECTION_COLORS[this.props.color];

    if (
      report?.sortBy === SortOption.HighestToLowest ||
      report?.sortBy === SortOption.LowestToHighest
    ) {
      // Setting color to default when sortoption is set to sort by highest to lowest or lowest to highest.
      sectionColor = undefined;
    }

    return (
      <div className="statements">
        <FlipMove
          staggerDurationBy={STAGGER_DURATION_BY_IN_MS}
          duration={DURATION_IN_MS}
          enterAnimation="accordionVertical"
          leaveAnimation="accordionVertical"
        >
          {statements.map((statement, i) => {
            const color = statement.sectionColor
              ? STATEMENT_SECTION_COLORS[statement.sectionColor]
              : STATEMENT_SECTION_COLORS[statement.color];

            const insightIcon = enableInsightIcon ? (
              <ThunderboltFilled
                className={cx('icon-box purple', {
                  invisible: !statement?.hasStatementInsight,
                })}
              />
            ) : null;

            return (
              <div key={`statement-${statement.id}`}>
                <ScoreBarRow
                  label={statement.statement}
                  circleValue={statement.score}
                  circleLabel={statement.scoreLabel}
                  circleColor={statement.color}
                  circleClassName={statement.scoreClasses}
                  rowColor={sectionColor || color}
                  diamondValue={!hideStatementBenchmark ? statement.benchmark : undefined}
                  showArrow={showArrow || statement.showArrow}
                  pins={statement.percentiles}
                  otherBenchmarks={statement.otherBenchmarks}
                  onClick={
                    !this.props.noRowClick
                      ? (event) => onGetInsightClick?.(event, statement.id)
                      : undefined
                  }
                  onHoveredOrFocused={(event) => onSelectStatement?.(event, statement.id)}
                  selected={statement.selected}
                  themed={!isCompassionateConversation}
                  themedActive={statement.themedActive}
                  customIcon={insightIcon}
                  showRowButton={showRowButton}
                />
              </div>
            );
          })}
        </FlipMove>
      </div>
    );
  }

  @computed
  get sectionHeader(): ReactElement {
    const { header, color, tooltip } = this.props;
    return (
      <div className="mt-0 mb-2 row no-gutters">
        <div className="order-1 d-flex align-items-center col-md-5 order-md-0 header">
          {color && <i className="far fa-circle header-icon" style={{ color }} />}
          <span className="my-0 d-inline-block header-text">{header}</span>
          {tooltip && (
            <Tooltip placement="right" title={tooltip}>
              <div>
                <i className="p-1 ml-1 fal fa-question-circle header-tip-icon" />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    );
  }

  render(): ReactElement {
    const { hideSectionHeaders } = this.props;

    return (
      <div className="align-focus-area-section">
        <div className={cx('flex-fill', { 'mb-4': !hideSectionHeaders })}>
          {!hideSectionHeaders && this.sectionHeader}
          {this.statements}
        </div>
      </div>
    );
  }
}

export default inject(STORE_BREAKPOINT)(observer(AlignFocusAreaSection));
