import React, { ReactElement, ReactNode } from 'react';

import { Col, MenuProps, Row } from 'antd';
import cx from 'classnames';

import styles from './EnhancedCard.module.scss';

import CardFooter from './CardFooter';
import { Clickable } from './CardFooter/CardFooter';
import CardHeader from './CardHeader';

interface GridOptions {
  columns: number;
  offset?: number;
}

interface EnhancedCardProps {
  children: ReactNode;
  className?: string;
  wrapperClassName?: string;
  heading?: string;
  headingRight?: ReactNode;
  subHeading?: ReactNode;
  subHeadingNote?: string;
  headerImage?: ReactNode;
  headerIcon?: ReactNode;
  headerTag?: ReactNode;
  headerButton?: Clickable;
  headerMenu?: MenuProps['items'];
  headerSelectMenu?: ReactNode;
  dropdownMenu?: ReactElement;
  headerBackgroundColor?: string;
  headerClassName?: string;
  footerTitle?: string;
  footer?: ReactNode;
  footerPrimaryButton?: Clickable;
  footerSecondaryButton?: Clickable;
  footerActionButton?: Clickable;
  footerTheme?: 'purple' | 'default';
  gridOptions?: GridOptions;
  collapsedModeContent?: ReactNode;
  collapsible?: boolean;
  isCollapsed?: boolean;
  headerTagClassName?: string;
  footerSticky?: boolean;
  onCollapseStateChange?: (status: boolean) => void;
  onClick?: () => void;
}

const EnhancedCard: React.FC<EnhancedCardProps> = ({
  className,
  wrapperClassName,
  children,
  heading,
  headingRight,
  subHeading,
  subHeadingNote,
  headerImage,
  headerIcon,
  headerTag,
  headerButton,
  headerMenu,
  headerSelectMenu,
  dropdownMenu,
  headerBackgroundColor,
  headerClassName,
  headerTagClassName,
  footerTitle,
  footerPrimaryButton,
  footerSecondaryButton,
  footerActionButton,
  footerTheme,
  footer,
  gridOptions,
  collapsible,
  isCollapsed,
  collapsedModeContent,
  footerSticky,
  onCollapseStateChange,
  onClick,
}) => {
  const withHeader = heading;
  const withFooter =
    footer ||
    footerTitle ||
    footerPrimaryButton ||
    footerSecondaryButton ||
    footerActionButton ||
    footerTheme;

  const cardHeader = (
    <CardHeader
      heading={heading}
      headingRight={headingRight}
      subHeading={subHeading}
      subHeadingNote={subHeadingNote}
      image={headerImage}
      icon={headerIcon}
      tag={headerTag}
      button={headerButton}
      menu={headerMenu}
      selectMenu={headerSelectMenu}
      dropdownMenu={dropdownMenu}
      backgroundColor={headerBackgroundColor}
      className={headerClassName}
      compact={gridOptions?.columns < 18}
      collapsible={collapsible}
      tagClassName={headerTagClassName}
      isCollapsed={isCollapsed}
      onCollapseStateChange={onCollapseStateChange}
    />
  );

  const cardFooter = (
    <CardFooter
      theme={footerTheme}
      title={footerTitle}
      primaryButton={footerPrimaryButton}
      secondaryButton={footerSecondaryButton}
      actionButton={footerActionButton}
      compact={gridOptions?.columns < 18}
      sticky={footerSticky}
    >
      {footer}
    </CardFooter>
  );

  return (
    <Row onClick={onClick} className={wrapperClassName}>
      <Col
        xs={24}
        sm={24}
        md={gridOptions?.columns || 24}
        lg={gridOptions?.columns || 24}
        className={cx(styles.container, className)}
      >
        {withHeader && cardHeader}
        {collapsible && isCollapsed && collapsedModeContent}
        {(!collapsible || !isCollapsed) && (
          <>
            {children}
            {withFooter && cardFooter}
          </>
        )}
      </Col>
    </Row>
  );
};

export default EnhancedCard;
