import React, { ReactElement } from 'react';

import './ExerciseStatementsLegend.scss';

export interface ExerciseStatementsLegendProps {
  scoreLegend?: string;
  benchmarkLegend?: string;
  customLegend?: ReactElement;
}

export const ExerciseStatementsLegend: React.FC<ExerciseStatementsLegendProps> = ({
  scoreLegend,
  benchmarkLegend,
  customLegend,
}) => (
  <div className="exercise-statements-legend" data-testid="legends">
    {scoreLegend && (
      <div className="d-flex align-items-center legend">
        <div className="d-flex">
          <div className="legend-circle m-1 red-legend" />
          <div className="legend-circle m-1 yellow-legend" />
          <div className="legend-circle m-1 green-legend" />
        </div>
        <div className="m-1">{scoreLegend}</div>
      </div>
    )}

    {benchmarkLegend && (
      <div className="d-flex align-items-center legend">
        <div className="d-flex">
          <div className="legend-benchmark-circle m-1 red-legend" />
          <div className="legend-benchmark-circle m-1 yellow-legend" />
          <div className="legend-benchmark-circle m-1 green-legend" />
        </div>
        <div className="m-1">{benchmarkLegend}</div>
      </div>
    )}

    {customLegend && <div className="d-flex align-items-center legend">{customLegend}</div>}
  </div>
);

export default ExerciseStatementsLegend;
