export enum Tool {
  Align = 'align',
  Reflect360 = 'reflect360',
  Perspective = 'perspective',
  Habit = 'habit',
  Connect = 'connect',
}

export enum ToolPath {
  TeamAligns = 'aligns',
  Reflect360 = 'reflect360',
  TeamHabits = 'teamHabits',
  PersonalHabits = 'personalHabits',
  Connect = 'connect',
  Home = 'home',
}

export const DefaultToolsPromotions = {
  teamAligns: [Tool.Perspective, Tool.Habit],
  reflect360: [Tool.Align, Tool.Perspective],
  teamConnect: [Tool.Perspective, Tool.Align],
  teamHabits: [Tool.Align, Tool.Perspective],
  teamPerspective: [Tool.Align, Tool.Habit],
};
